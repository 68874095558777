import React, {ReactElement, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import classnames from 'classnames';
import {
  Button,
  CompaniesBarChartDashboard,
  CompaniesBubbleChartDashboard,
  CompaniesTreemapChartDashboard,
  IconButton,
  SectionHeader,
  SegmentedControl,
  Tabs,
} from '../../';
import {
  fundListSwitchOptionsForActiveCompanies,
  portfolioInvestmentsTabs,
} from '../../../constants';
import {
  ActiveDashboardView,
  ButtonStyle,
  CompaniesSortPayload,
  CompaniesTiles,
  DataVisualisaitonChartType,
  DataVisualisationDataSource,
  Func,
  PortfolioCompaniesChartData,
  Tab,
} from '../../../types';
import {SegmentedControlVariants} from '../../global/segmented-control/SegmentedControl';
import {useTranslation} from 'react-i18next';
import styles from './AllCompaniesDashboard.module.scss';
import {useAppDispatch} from '../../../hooks/useReduxHooks';
import {
  getActiveCompaniesQuarterlyDataAction,
  setChartDataSource,
} from '../../../reducers/portfolioCompaniesChartDataSlice';

interface AllCompaniesDashboardProps {
  id: string;
  tiles: CompaniesTiles | null;
  tables: {
    active: ReactElement;
    exited: ReactElement;
    writtenOff: ReactElement;
  };
  className?: string;
  onCompaniesSort?: Func<[CompaniesSortPayload], void>;
  exportHandler?: Func<[ActiveDashboardView, Tab], void>;
  exportInProgress?: boolean;
  chartData: PortfolioCompaniesChartData;
  releaseCompanyDataVisualisationEnabled: any;
  handleSyncChartConfirmationModal: Func<[DataVisualisaitonChartType], void>;
}

const AllCompaniesDashboard: React.FC<AllCompaniesDashboardProps> = ({
  id,
  tiles,
  tables,
  className = '',
  onCompaniesSort,
  exportHandler = () => null,
  exportInProgress,
  chartData,
  releaseCompanyDataVisualisationEnabled,
  handleSyncChartConfirmationModal,
}): JSX.Element => {
  const {t} = useTranslation();
  const {location} = useHistory();
  const dispatch = useAppDispatch();

  const [activeTab, setActiveTab] = useState<Tab>(portfolioInvestmentsTabs[0]);
  const [activeView, setActiveView] = useState(ActiveDashboardView.Table);
  const [isAcceptingCustomUrlSearchParam, setIsAcceptingCustomUrlSearchParam] =
    useState(true);

  useEffect(() => {
    if (
      activeTab.value !== 'active' &&
      (activeView === ActiveDashboardView.BarChart ||
        activeView === ActiveDashboardView.TreemapChart ||
        activeView === ActiveDashboardView.ScatteredBubbles)
    )
      setActiveView(ActiveDashboardView.Table);
  }, [activeTab.value, activeView]);

  useEffect(() => {
    if (location.search && isAcceptingCustomUrlSearchParam) {
      const searchParam = new URLSearchParams(location.search);
      const param = searchParam.get('chart');
      const view =
        param === DataVisualisaitonChartType.BarChart
          ? ActiveDashboardView.BarChart
          : param === DataVisualisaitonChartType.TreemapChart
          ? ActiveDashboardView.TreemapChart
          : param === DataVisualisaitonChartType.BubbleChart
          ? ActiveDashboardView.ScatteredBubbles
          : null;
      if (view) {
        setActiveView(view);
        setIsAcceptingCustomUrlSearchParam(false);
      }
    }
  }, [location.search, isAcceptingCustomUrlSearchParam]);

  const showSyncChartConfirmationModal = (): void => {
    const chartType =
      activeView === ActiveDashboardView.BarChart
        ? DataVisualisaitonChartType.BarChart
        : activeView === ActiveDashboardView.TreemapChart
        ? DataVisualisaitonChartType.TreemapChart
        : activeView === ActiveDashboardView.ScatteredBubbles
        ? DataVisualisaitonChartType.BubbleChart
        : null;
    if (chartType) {
      handleSyncChartConfirmationModal(chartType);
    }
  };

  const handleChangeDataSource = (value: string | number) => {
    dispatch(
      setChartDataSource({
        dataSource: value as DataVisualisationDataSource,
      })
    );

    dispatch(getActiveCompaniesQuarterlyDataAction('asAt'));
  };

  return (
    <div id={id} className={classnames(styles.wrapper, className)}>
      <SectionHeader
        onClick={() => null}
        withActionButton={false}
        className={styles.sectionHeader}
        childrenLeft={[
          <Tabs
            tabs={portfolioInvestmentsTabs}
            activeTab={activeTab}
            clickHandler={tab => setActiveTab(tab)}
          />,
        ]}
        childrenRight={[
          activeTab.value === 'active' &&
          !!releaseCompanyDataVisualisationEnabled ? (
            <SegmentedControl
              key={
                activeTab.value === 'active'
                  ? 'active-companies'
                  : 'other-companies'
              }
              id={`${id}-segmented-control-view-options`}
              options={fundListSwitchOptionsForActiveCompanies}
              onChange={val => setActiveView(val as ActiveDashboardView)}
              value={activeView}
              variant={SegmentedControlVariants.Secondary}
              disabled={!tiles}
            />
          ) : null,
          <IconButton
            id={`${id}-export`}
            onClick={() => exportHandler(activeView, activeTab)}
            styleType={ButtonStyle.Primary}
            icon="export"
            loading={exportInProgress}
            title="Export"
          />,
        ]}
      />
      {[
        ActiveDashboardView.BarChart,
        ActiveDashboardView.ScatteredBubbles,
        ActiveDashboardView.TreemapChart,
      ].includes(activeView) ? (
        <div className={styles.dataSource}>
          <SegmentedControl
            id={`${id}-segmented-control-data-source-options`}
            options={[
              {
                id: DataVisualisationDataSource.FundInvestment,
                label: t('Global.FundInvestment'),
                value: DataVisualisationDataSource.FundInvestment,
              },
              {
                id: 'portfolio-companies',
                label: t('Global.PortfolioMonitoring'),
                value: DataVisualisationDataSource.PortfolioMonitoring,
              },
            ]}
            value={chartData.dataSource}
            onChange={handleChangeDataSource}
          />
          <Button
            text={t('CompanyDashboard.SectionHeader.SyncChart')}
            styleType={ButtonStyle.Primary}
            onClick={showSyncChartConfirmationModal}
            className={styles.syncButton}
            disabled={
              chartData.dataSource ===
              DataVisualisationDataSource.PortfolioMonitoring
            }
            id={`${id}-sync-chart-btn`}
          />
        </div>
      ) : null}
      {activeView === ActiveDashboardView.Table && (
        <div className={styles.tableWrapper}>
          {/*  ActivePortfolioCompaniesTable */}
          {activeTab.label === 'Global.Active' && tables.active}
          {/* ExitedPortfolioCompaniesTable */}
          {activeTab.label === 'Global.Exited' && tables.exited}
          {/* WrittenOffPortfolioCompaniesTable */}
          {activeTab.label === 'Global.WrittenOff' && tables.writtenOff}
        </div>
      )}
      {activeView === ActiveDashboardView.BarChart && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesBarChartDashboard
            data={chartData.barChartData || []}
            filterData={chartData.barChartFilter || []}
            barChartDataRequestPending={chartData.barChartDataRequestPending}
          />
        </div>
      )}
      {activeView === ActiveDashboardView.TreemapChart && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesTreemapChartDashboard
            data={chartData.treemapData || []}
            filterData={chartData.treemapFilter || []}
            treemapDataRequestPending={chartData.treemapDataRequestPending}
          />
        </div>
      )}
      {activeView === ActiveDashboardView.ScatteredBubbles && (
        <div className={styles.chartDashboardContainer}>
          <CompaniesBubbleChartDashboard
            data={chartData.bubbleChartData || []}
            filterData={chartData.bubbleChartFilter || []}
            bubbleChartDataRequestPending={
              chartData.bubbleChartDataRequestPending
            }
          />
        </div>
      )}
    </div>
  );
};

export default AllCompaniesDashboard;
