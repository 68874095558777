import React, {RefObject, useEffect, useRef, useState} from 'react';

interface AnimatedCountProps {
  number: number;
  duration: number;
  delay?: number;
  isInSvg?: boolean;
}

const AnimatedCount: React.FC<AnimatedCountProps> = ({
  number,
  duration,
  delay = 0,
  isInSvg = false,
}) => {
  const [count, setCount] = useState('0');
  const ref = useRef<SVGTSpanElement | HTMLSpanElement>(null);

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = number;
    // if zero, return
    if (start === end) {
      setCount(`${start}`);
      return;
    }

    // find duration per increment
    let incrementTime = 0;
    if (end < 2) {
      incrementTime = duration / (end * 100);
    } else {
      incrementTime = duration / end;
      const numberAfterDecimal = number.toString().split('.')[1];
      start = +(
        start +
        (numberAfterDecimal !== undefined ? `.${numberAfterDecimal}` : '')
      );
    }
    // timer increments start counter
    // then updates count
    // ends if start reaches end
    setTimeout(() => {
      let timer = setInterval(() => {
        if (end < 2) {
          start = +(start + 0.01).toFixed(2);
        } else {
          start = +(start + 1).toFixed(2);
        }
        if (ref.current) {
          setCount(`${start}`);
        }
        if (start >= +end.toFixed(2)) clearInterval(timer);
      }, incrementTime);
    }, delay);

    // dependency array
  }, [number, duration, delay]);

  if (isInSvg) {
    return <tspan ref={ref as RefObject<SVGTSpanElement>}>{count}</tspan>;
  }

  return <span ref={ref as RefObject<HTMLSpanElement>}>{count}</span>;
};

export default AnimatedCount;
