import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {RootState} from '../../store';
import {acquireAuthResult, msalInstance} from '../..';
import {errorHandlerAction, pageErrorHandlerAction} from '../../actions';
import {
  CapitalWidgetType,
  ComponentWidgetType,
  FundStructure,
} from '../../types';
import {
  getFundDetailsStructure,
  getFundEntityCapitalData,
  getFundInvestorsData,
} from '../../api/getFundDetailsStructure';
import {
  clearFundStructureData,
  getFundStructureCapitalData,
  getFundStructureInvestorsData,
  setFundStructureData,
  updateFundStructureCapitalData,
  updateFundStructureInvestorsData,
} from '../../reducers/fundDetailsStructureSlice';
import {PayloadAction} from '@reduxjs/toolkit';

export const fundDetailsStructureSaga = function* fundDetailsStructureSaga({
  payload,
}: any): any {
  const id: string = payload?.match?.params?.id;
  if (id) {
    const ga760StructurePage = yield select(
      (state: RootState) => state.featureFlags.data.ga760StructurePage
    );

    if (ga760StructurePage) {
      window.location.replace(`/fund-monitoring/funds/${id}/structure`);
    }

    const isUserProfileUpdatePending = yield select(
      (state: RootState) => state.userProfile.isLoading
    );
    if (!isUserProfileUpdatePending) {
      yield put(clearFundStructureData());
    }
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: FundStructure = yield call(
        getFundDetailsStructure,
        accessToken,
        id
      );
      yield put(setFundStructureData(response));
    } catch (err) {
      console.warn(err);
      yield put(pageErrorHandlerAction({error: err}));
    }
  }
};

export const updateFundEntityCapitalDataSaga = function* updateCapitalDataSaga({
  payload,
}: PayloadAction<{fundId: string; date: string}>) {
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const {fundId, date} = payload;
    const response: CapitalWidgetType[] = yield call(
      getFundEntityCapitalData,
      accessToken,
      fundId,
      date
    );
    yield put(
      updateFundStructureCapitalData({widgets: response, fundId: fundId})
    );
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateFundStructureInvestorsDataSaga =
  function* updateFundStructureInvestorsDataSaga({
    payload,
  }: PayloadAction<{
    fundId: string;
    sortValue: string;
    sortDirection: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const {fundId, sortValue, sortDirection} = payload;
      const response: ComponentWidgetType[] = yield call(
        getFundInvestorsData,
        accessToken,
        fundId,
        sortValue,
        sortDirection
      );
      yield put(updateFundStructureInvestorsData(response));
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const fundDetailsStructureSagaWatcher =
  function* fundDetailsStructureSagaWatcher() {
    yield all([
      takeLatest(
        getFundStructureInvestorsData.type,
        updateFundStructureInvestorsDataSaga
      ),
      takeLatest(
        getFundStructureCapitalData.type,
        updateFundEntityCapitalDataSaga
      ),
    ]);
  };
