import {Workbook, Worksheet} from 'exceljs';
import {saveAs} from 'file-saver';
import {toPng, toSvg} from 'html-to-image';
import {
  TableExportPayload,
  HtmlToBlobOpts,
  ColumnOrderState,
  HeaderMapping,
} from '../types';
import {parseISO} from 'date-fns';

export const exportTableToXlsx = async (
  exportPayloads: TableExportPayload[]
) => {
  const wb = new Workbook();

  exportPayloads.forEach(exportPayload => {
    genWorkSheet(wb, exportPayload);
  });

  const buffer = await wb.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  // @TODO Export file naming will be refactored in next sprints as new requirements are coming..
  const name = getXlsxFileName(
    exportPayloads[0].settings.fileName ?? exportPayloads[0].settings.name
  );

  return {
    name,
    blob,
  };
};

const genWorkSheet = (wb: Workbook, exportPayload: TableExportPayload) => {
  const ws = wb.addWorksheet(exportPayload.settings.name);
  // ws.properties.defaultRowHeight = 40;
  // ws.views = [{}];

  // Generate the column styles and header data
  genColumnsWithStyles(ws, exportPayload);
  // Generate the rows data
  genRowsData(ws, exportPayload);

  // Apply styles to header row
  styleHeaderRow(ws);

  // Apply formatting to columns
  formatColumns(ws, exportPayload);
};

const formatColumns = (ws: Worksheet, exportPayload: TableExportPayload) => {
  const {meta, columnVisibility} = exportPayload.settings;
  Object.entries(meta.columns).forEach((item: any) => {
    const [key, value] = item;
    if (value?.formatter && columnVisibility[key] === undefined) {
      const [type, format] = value.formatter.trim().split(':');
      ws.getColumn(key).eachCell((cell: any) => {
        cell[type] = format;
      });
    }
  });
};

const genOrderedHeaderMapping = (
  order: ColumnOrderState,
  headerMapping: HeaderMapping[]
) => {
  return order.map(columnId => {
    const header = headerMapping.find(header => header.columnId === columnId);
    return header
      ? header
      : {
          columnId,
          label: '',
        };
  });
};

const genColumnsWithStyles = (
  ws: Worksheet,
  exportPayload: TableExportPayload
) => {
  const tableHeaderData: any[] = [];
  const {mappings, settings} = exportPayload;
  const getColumnWidth = (columnList: string[], columnId: string) =>
    columnList.includes(columnId) ? 25 : 16;

  const orderedHeaderMapping = settings.columnOrder
    ? genOrderedHeaderMapping(settings.columnOrder, mappings)
    : mappings;

  orderedHeaderMapping.forEach(header => {
    if (
      settings.meta.columns[header.columnId] &&
      settings.columnVisibility[header.columnId] !== false
    ) {
      tableHeaderData.push({
        header: header.label,
        key: header.columnId,
        width: getColumnWidth(
          ['fundName', 'name', 'companyName'],
          header.columnId
        ),
        // height: 4,
        style: {
          alignment: {
            vertical: 'middle',
            horizontal: settings.meta.columns[header.columnId].styling.align,
          },
        },
      });
    }
  });

  // Set the columns styling
  ws.columns = tableHeaderData;
};

const isObject = (data: any) =>
  typeof data === 'object' && data !== null && !Array.isArray(data);

const getNestedFromHeaderData = (
  data: Array<any>,
  accessor: string
): string | number | null => {
  const item = data.find((item: any) => item.id === accessor);
  return item ? item.value : null;
};

// Will be updated in export refactoring alongside the other things.
const getFormattedData = (formatter: string, data: any): any => {
  if (formatter.includes('date') || formatter.includes('numFmt')) {
    const date = data?.trim();
    return date
      ? parseISO(date.length > 12 ? date : `${date}T00:00:00+0000`)
      : '';
  }

  if (formatter === 'percentage') {
    return data ? data / 100 : '';
  }
};

const getCellData = (cell: any): any => {
  if (cell !== undefined && isObject(cell)) return cell.value;

  return cell ? cell : '';
};

const getAccessAsNestedArray = (row: any, metaDef: any) => {
  const nestedArray = row[metaDef.target.array];
  if (!nestedArray) return row[metaDef.fallback];

  return nestedArray
    .map((item: any) => item[metaDef.target.property])
    .join(', ');
};

const genRowsData = (ws: Worksheet, exportPayload: TableExportPayload) => {
  const {data, settings} = exportPayload;

  const tableRowsData: any[] = data.map((row: any) => {
    return settings.columnOrder
      .filter(columnId => settings.columnVisibility[columnId] !== false)
      .map(columnId => {
        const metaColumn = settings.meta.columns[columnId];
        const cell = row[columnId];
        if (metaColumn.accessFromHeaderData) {
          return getNestedFromHeaderData(
            row.headerData,
            metaColumn.accessFromHeaderData
          );
        }

        if (metaColumn.accessAsNestedArray) {
          return getAccessAsNestedArray(row, metaColumn.accessAsNestedArray);
        }

        if (metaColumn.accessAlias) {
          const cellFromAlias = row[metaColumn.accessAlias];
          return cellFromAlias ? cellFromAlias : '';
        }

        if (metaColumn.formatter) {
          const formattedData = getFormattedData(
            metaColumn.formatter,
            getCellData(cell)
          );
          return formattedData ? formattedData : '';
        }

        if (cell !== undefined && isObject(cell)) {
          return cell.value;
        }

        if (cell !== undefined && Array.isArray(cell)) {
          return cell.join(', ');
        }

        if (cell === undefined) {
          return '';
        }

        return cell;
      });
  });

  ws.addRows(tableRowsData);
};

const styleHeaderRow = (ws: Worksheet) => {
  const headerRow = ws.getRow(1);
  headerRow.font = {
    bold: true,
  };
  headerRow.eachCell(cell => {
    cell.fill = {
      type: 'pattern',
      pattern: 'darkGray',
      // pattern: 'solid',
      fgColor: {argb: 'D3D3D3'},
    };
  });
};

const getXlsxFileName = (name: string) => {
  return `${name}.xlsx`.replace(/\s+/g, '');
};

export const htmlToImage = async (id: string, opts: HtmlToBlobOpts) => {
  const el = document.getElementById(id);
  if (el === null) {
    throw new Error('Can not find corresponding element for the provided id.');
  }

  // Remove elements listed in the elsToFilter option
  const filter = (node: HTMLElement) => {
    return opts?.elsToFilter
      ? !opts?.elsToFilter.some(classname =>
          node.classList?.contains(classname)
        )
      : false;
  };

  // Export options
  const exportOptions = {
    type: opts.type,
    cacheBust: true,
    backgroundColor: opts?.bgColor,
    filter: opts?.elsToFilter ? filter : undefined,
  };

  return opts.type === 'image/png'
    ? toPng(el, exportOptions)
    : toSvg(el, exportOptions);
};

export const downloadXLSXExport = ({name, blob}: {name: string; blob: any}) => {
  saveAs(blob, name);
};

export const downloadZippedExport = ({
  name,
  blob,
}: {
  name: string;
  blob: any;
}) => {
  // Keep just portfolio or fund name for the file name as defined in the ticket EM-341
  const zipFileName = name.split('_')[0];
  saveAs(blob, `${zipFileName}.zip`);
};
