/* eslint-disable no-console */
import {
  call,
  put,
  all,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../store';
import {
  getActiveFundInvestmentQuarterlyData,
  getActivePortfolioMonitoringQuarterlyData,
  getAllCompanies,
  saveChartDataFilterConfig,
} from '../../api/getAllCompanies';
import {errorHandlerAction} from '../../actions';
import {acquireAuthResult, msalInstance} from '../../';
import {
  ChartDataFilterConfig,
  ClientActiveCompaniesResponse,
  Companies,
  DataVisualisaitonChartType,
  DataVisualisationDataSource,
  InvestmentPerformanceData,
  KpiFilterOption,
  PortfolioCompaniesChartData,
} from '../../types';
import {
  getCompanyPerformanceDataAction,
  setCompaniesList,
  setCompanyPerformanceData,
  setTilesData,
  clearCompaniesList,
} from '../../reducers/companiesSlice';

import {getInvestmentPerformanceData} from '../../api/getFundDetailsPortfolio';
import {
  getActiveCompaniesQuarterlyDataAction,
  saveChartFilterConfigAction,
  setChartDataRequestPending,
  setChartDataSource,
  setPortfolioCompaniesChartData,
} from '../../reducers/portfolioCompaniesChartDataSlice';
import {
  getAllDataVisualisationChartDataRequestPendingArray,
  getDataVisualisationChartDataRequestPendingKey,
  usersResponseErrorResolver,
} from '../../utils';
import {setGlobalInformation} from '../../reducers/globalInformationSlice';
import {axisMetricsOptions, saveChartCompleteModal} from '../../constants';

export const getAllCompaniesSaga = function* getAllCompaniesSaga({
  payload,
}: any): any {
  // Feature Flags
  const beac935EnablePortfolio = yield select(
    (state: RootState) => state.featureFlags.data.beac935EnablePortfolio
  );
  if (beac935EnablePortfolio) {
    window.location.replace(`/portfolio/companies`);
  }
  //
  const isUserProfileUpdatePending = yield select(
    (state: RootState) => state.userProfile.isLoading
  );
  if (!isUserProfileUpdatePending) {
    yield put(clearCompaniesList());
  }
  try {
    const {accessToken} = yield call(acquireAuthResult, msalInstance);
    const response: Companies = yield call(getAllCompanies, accessToken);
    yield put(setCompaniesList(response));
    yield put(
      setTilesData({
        active: response.active.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.irr?.value! - a.irr?.value!),
        exited: response.exited.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.irr?.value! - a.irr?.value!),
        writtenOff: response.writtenOff.data
          .flatMap(item => item.investmentDetails ?? item)
          .sort((a, b) => b.realisedLoss?.value! - a.realisedLoss?.value!),
      })
    );

    const {barChartData, bubbleChartData, treemapData} = yield select(
      (state: RootState) => state.companiesChartData.data
    );

    const shouldResetChartData = payload?.shouldResetChartData;

    const chartTypes = [
      ...(shouldResetChartData || !barChartData
        ? [DataVisualisaitonChartType.BarChart]
        : []),
      ...(shouldResetChartData || !bubbleChartData
        ? [DataVisualisaitonChartType.BubbleChart]
        : []),
      ...(shouldResetChartData || !treemapData
        ? [DataVisualisaitonChartType.TreemapChart]
        : []),
    ];
    yield put(
      setPortfolioCompaniesChartData({
        companies: response.active.data,
        quarterlyData: response.quarterlyData,
        chartTypes,
        axisMetricsOptions,
      })
    );
  } catch (err) {
    console.warn(err);
    yield put(errorHandlerAction({error: err}));
  }
};

export const updateCompanyPerformanceDataSaga =
  function* updateCompanyPerformanceDataSaga({
    payload,
  }: PayloadAction<{
    companyId: string;
    companyType: 'active' | 'exited';
    currency: string;
  }>) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      const response: Array<InvestmentPerformanceData> = yield call(
        getInvestmentPerformanceData,
        accessToken,
        payload.companyId,
        'portfolio-company-performance',
        'true',
        '',
        payload.currency
      );
      yield put(
        setCompanyPerformanceData({
          performanceData: response,
          companyId: payload.companyId,
          companyType: payload.companyType,
          currency: payload.currency,
        })
      );
    } catch (err) {
      console.warn(err);
      yield put(errorHandlerAction({error: err}));
    }
  };

export const getActiveCompaniesQuarterlyDataSaga =
  function* getActiveCompaniesQuarterlyDataSaga({
    payload,
  }: PayloadAction<{
    name: string;
    date?: string;
    chartType?: DataVisualisaitonChartType;
    filterConfig?: KpiFilterOption[];
  }>) {
    const {dataSource}: PortfolioCompaniesChartData = yield select(
      (state: RootState) => state.companiesChartData.data
    );

    let requestPendingKey;

    if (payload.chartType) {
      requestPendingKey = getDataVisualisationChartDataRequestPendingKey(
        payload.chartType
      );
    }

    const getActiveQuarterlyData =
      dataSource === DataVisualisationDataSource.FundInvestment
        ? getActiveFundInvestmentQuarterlyData
        : getActivePortfolioMonitoringQuarterlyData;

    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);
      yield put(setChartDataSource({dataSource: dataSource}));

      yield put(
        setChartDataRequestPending(
          requestPendingKey
            ? {requestPendingKey, isLoading: true}
            : getAllDataVisualisationChartDataRequestPendingArray(true)
        )
      );

      const response: ClientActiveCompaniesResponse = yield call(
        getActiveQuarterlyData,
        accessToken,
        payload.date
      );

      yield put(
        setPortfolioCompaniesChartData({
          companies: response.companies,
          quarterlyData: response.quarterlyData,
          chartTypes: payload.chartType
            ? [payload.chartType]
            : [
                DataVisualisaitonChartType.BarChart,
                DataVisualisaitonChartType.BubbleChart,
                DataVisualisaitonChartType.TreemapChart,
              ],
          filterConfig: payload.filterConfig,
          axisMetricsOptions: response.axisMetricsOptions,
        })
      );
    } catch (err) {
      console.warn(err);
      // Revert dataSource if request fails
      yield put(
        setChartDataSource({
          dataSource:
            dataSource === DataVisualisationDataSource.FundInvestment
              ? DataVisualisationDataSource.PortfolioMonitoring
              : DataVisualisationDataSource.FundInvestment,
        })
      );
      yield put(errorHandlerAction({error: err}));
    } finally {
      yield put(
        setChartDataRequestPending(
          requestPendingKey
            ? {requestPendingKey, isLoading: false}
            : getAllDataVisualisationChartDataRequestPendingArray(false)
        )
      );
    }
  };

export const getSavedFilterConfigCompaniesHelperSaga =
  function* getSavedFilterConfigCompaniesHelperSaga({
    payload,
  }: PayloadAction<{
    config: ChartDataFilterConfig[];
  }>) {
    const {config} = payload;

    for (let i = 0; i < config.length; i++) {
      const {chartType, filterConfig} = config[i];
      const savedChartType =
        chartType === DataVisualisaitonChartType.BarChart
          ? DataVisualisaitonChartType.SavedBarChart
          : chartType === DataVisualisaitonChartType.BubbleChart
          ? DataVisualisaitonChartType.SavedBubbleChart
          : chartType === DataVisualisaitonChartType.TreemapChart
          ? DataVisualisaitonChartType.SavedTreemapChart
          : null;
      if (savedChartType) {
        yield put(
          getActiveCompaniesQuarterlyDataAction(
            savedChartType,
            filterConfig.filter(el => el.name === 'asAt')[0].value as string,
            savedChartType,
            filterConfig
          )
        );
      }
    }
  };

export const saveChartFilterConfigSaga = function* saveChartFilterConfigSaga({
  payload,
}: PayloadAction<{
  chartType: DataVisualisaitonChartType;
}>) {
  const {chartType} = payload;
  const data: PortfolioCompaniesChartData = yield select(
    (state: RootState) => state.companiesChartData.data
  );
  const filterConfig =
    chartType === DataVisualisaitonChartType.BarChart
      ? data.barChartFilter
      : chartType === DataVisualisaitonChartType.TreemapChart
      ? data.treemapFilter
      : chartType === DataVisualisaitonChartType.BubbleChart
      ? data.bubbleChartFilter
      : null;

  if (filterConfig) {
    try {
      const {accessToken} = yield call(acquireAuthResult, msalInstance);

      // save config to db: returns 204 No content
      yield call(saveChartDataFilterConfig, accessToken, {
        chartType,
        filterConfig,
      });
      // show save chart complete modal
      yield put(setGlobalInformation(saveChartCompleteModal(chartType)));
    } catch (err) {
      console.warn(err);
      // error resolver helper
      const responseError = usersResponseErrorResolver(err);
      if (responseError) {
        yield put(setGlobalInformation(responseError));
      }
    }
  }
};

export const allCompaniesSagaWatcher = function* allCompaniesSagaWatcher() {
  yield all([
    takeLatest(
      getCompanyPerformanceDataAction.type,
      updateCompanyPerformanceDataSaga
    ),
    takeEvery(
      getActiveCompaniesQuarterlyDataAction.type,
      getActiveCompaniesQuarterlyDataSaga
    ),
    takeLatest(saveChartFilterConfigAction.type, saveChartFilterConfigSaga),
  ]);
};
